@import './assets/css/variables.scss';

* {
  font-family: 'Pretendard', 'Noto Sans', 'Lato', 'Sans-serif';
  -webkit-overflow-scrolling: touch;
}
html,
body {
  min-width: $container-width !important;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  touch-action: auto;
}

ul {
  margin-block-start: 4px;
  padding-inline-start: 32px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

input {
  font-size: 15px;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: 'textfield';
  }
  &:focus-visible {
    outline: none;
  }
}

textarea {
  &:focus-visible {
    outline: none;
  }
}

.terms {
  color: var(--Neutral-100, #242424);
  font-family: Pretendard;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;

  .title-1 {
    color: var(--Neutral-100, #242424);
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
  }

  .title-2 {
    color: var(--Neutral-100, #242424);
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
  }

  .title-3 {
    color: var(--Neutral-100, #242424);
    font-family: Pretendard;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
  }
}

.ProseMirror:focus {
  outline: none;
}

.ProseMirror {
  height: 100%;
  line-height: 28px;
  overflow: auto;
  color: var(--Neutral-90, #424242);
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;

  img {
    width: 100%;
  }

  h1 {
    color: var(--Neutral-90, #424242);

    font-family: Pretendard;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 133.333% */
  }

  .is-editor-empty:first-child::before {
    content: attr(data-placeholder);
    float: left;
    color: #adb5bd;
    pointer-events: none;
    height: 0;
  }

  .is-empty::before {
    color: #adb5bd;
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }
}

.lounge-editor-link-preview {
  width: 100%;
  div {
    overflow: hidden;
  }
  line-height: normal;
}

.lounge-editor-paragraph {
  margin-block-start: 4px;
  margin-block-end: 4px;
}

.lounge-editor-blockquote {
  display: flex;
  padding: 36px 20px 16px 20px;
  margin: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: var(--Neutral-20, #eff1f4);
  background-image: url('./assets/images/editor/quotes.svg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: top;
  background-position-y: 12px;
  color: var(--Neutral-80, #66686b) !important;
  text-align: center !important;
  font-family: Pretendard !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
}

.membership-badge {
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 12px;
  color: #ffffff;
  text-align: center;
}

.react-calendar {
  width: 100% !important;
  border: none !important;
  font-family: 'Pretendard', 'Noto Sans', 'Lato', 'Sans-serif' !important;

  &__navigation {
    margin-bottom: 0;
    &__label {
      color: #242424 !important;
      font-size: 18px;
      font-weight: 700;
      background-color: white !important;
    }
    &__arrow {
      background-color: white !important;
    }
  }
  &__century-view {
    &__decades {
      &__decade {
        border-radius: 10px !important;
      }
    }
  }
  &__decade-view {
    &__years {
      &__year {
        border-radius: 10px !important;
      }
    }
  }
  &__year-view {
    &__months {
      &__month {
        border-radius: 10px !important;
      }
    }
  }
  &__month-view {
    &__weekdays {
      color: #b9bbbe;

      font-size: 15px !important;
      font-weight: 400;
      line-height: normal;

      &__weekday {
        width: 40px;
        height: 32px;

        abbr {
          text-decoration: none;
        }
      }
    }
    &__days {
      font-size: 15px !important;
      padding-top: 16px;
      font-family: Pretendard;
      font-weight: 600;
      line-height: normal;

      &__day {
        color: #242424 !important;
        font-size: 15px !important;
        aspect-ratio: 1;
        &--weekend {
          color: #fc4242 !important;
        }
      }
    }
  }
  &__tile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #242424;

    &:focus {
      border-radius: 50%;
    }
    &:enabled:hover {
      border-radius: 50%;
    }

    &--now {
      background: white !important;

      &:enabled:hover {
        background: #e6e6e6 !important;
      }
    }
    &--active {
      border-radius: 50%;
      background: #ff3d8f !important;
      color: white !important;

      &:enabled:focus {
        border-radius: 50%;
        background: #ff3d8f !important;
      }
      &:enabled:hover {
        background: #ff3d8f !important;
      }
    }
    &:disabled {
      background: white !important;
      color: #d5d8dd !important;
    }
  }
}

.horizontal-center-align {
  display: flex;
  align-items: center;
}
.vertical-center-align {
  display: flex;
  justify-content: center;
}
.center-align {
  display: flex;
  justify-self: center;
  align-items: center;
}

.appBannerBottom {
  display: none;
}

.link-button {
  text-decoration: none;
  border: none;
  background-color: transparent;
  display: inline;
  margin: 0;
  padding: 0;
  outline: none;
  cursor: pointer;
}

.home-button {
  @extend .link-button;
}

.app-header-title {
  align-self: center;
  font-size: 17px;
  font-weight: bold;
  color: #242424;
}

.ant-modal-title {
  font-size: 20px !important;
}

// }

// Select 컴포넌트 열렸을때 아이템들
.ant-select-dropdown-menu-item {
  font-size: $text-1 !important;
  text-align: center;
}
// Components
// 기본 버튼
.ant-form-item-children {
  display: grid;
}
.App.ant-layout-has-sider {
  flex-direction: column !important;
}

.ant-btn.btn-default {
  height: 40px !important;
  color: #4e515d !important;
  font-size: 14px !important;
  font-weight: 300 !important;
  letter-spacing: -0.2px !important;
  border: 1px solid #c4c5c9;
}
.ant-btn.btn-default-small {
  height: 28px !important;
  font-size: 12px !important;
  letter-spacing: -0.2px !important;
  color: #4e515d !important;
  border: 1px solid #c4c5c9;
}
.ant-btn.btn-default:hover,
.ant-btn.btn-default:focus,
.ant-btn.btn-default-small:hover,
.ant-btn.btn-default-small:focus {
  color: $color-primary-1 !important;
  border-color: $color-primary-1;
}
.ant-btn.btn-default:active,
.ant-btn.btn-default-small:active {
  color: $color-primary-1 !important;
  border-color: $color-primary-1;
}
.ant-btn.ant-btn-clicked.btn-default:after,
.ant-btn.ant-btn-clicked.btn-default-small:after {
  border-color: $color-primary-1 !important;
}
.ant-btn.btn-default[disabled],
.ant-btn.btn-default-small[disabled] {
  color: #c4c5c9 !important;
}
// Primary 버튼
.ant-btn.btn-primary {
  width: 140px;
  height: 40px;
  font-size: 14px !important;
  color: #ffffff !important;
  letter-spacing: -0.2px !important;
  line-height: 26px !important;
  border-color: transparent !important;
  background-color: $color-primary-1 !important;
  border-radius: 4px !important;
}
.ant-btn.btn-primary:hover,
.ant-btn.btn-primary:active,
.ant-btn.btn-primary:focus {
  opacity: 0.8;
}
.ant-btn.btn-primary-small {
  font-size: 12px !important;
  color: #ffffff !important;
  letter-spacing: -0.2px !important;
  line-height: 23px !important;
  border-color: transparent !important;
  background-color: $color-primary-1 !important;
  border-radius: 4px !important;
}
.ant-btn.btn-primary-small:hover,
.ant-btn.btn-primary-small:active,
.ant-btn.btn-primary-small:focus {
  opacity: 0.8;
}
.ant-btn.btn-primary[disabled],
.ant-btn.btn-primary-small[disabled] {
  background-color: #cbced8 !important;
}
// Gray 버튼
.ant-btn.btn-gray {
  width: 140px;
  height: 40px;
  font-size: 14px !important;
  color: #ffffff !important;
  letter-spacing: -0.2px !important;
  line-height: 26px !important;
  border-color: transparent !important;
  background-color: #cecece !important;
  border-radius: 4px !important;
}
.ant-btn.btn-gray:hover,
.ant-btn.btn-gray:active,
.ant-btn.btn-gray:focus {
  opacity: 0.8;
}
.ant-btn.btn-gray-small {
  font-size: 12px !important;
  color: #ffffff !important;
  letter-spacing: -0.2px !important;
  line-height: 23px !important;
  border-color: transparent !important;
  background-color: #cecece !important;
  border-radius: 4px !important;
}
.ant-btn.btn-gray-small:hover,
.ant-btn.btn-gray-small:active,
.ant-btn.btn-gray-small:focus {
  opacity: 0.8;
}
.ant-btn.btn-gray[disabled],
.ant-btn.btn-gray-small[disabled] {
  background-color: #cbced8 !important;
}

.App,
.app-modal {
  // 레이아웃 큰틀
  &.ant-layout {
    min-height: 100%;
    background: #fff;
  }

  // 레이아웃 헤더
  & > .ant-layout-header {
    height: 80px;
    line-height: auto !important;
    // padding: 5px;
    position: fixed;
    z-index: 99;
    // margin: 0 auto;
    width: 100%;
    // min-width: 1188px;
    // border:none;
    .ant-layout-has-sider {
      flex-direction: column !important;
    }
  }
  // Form overriding
  .ant-form-item-label label {
    font-size: 16px;
    color: #454854;
    letter-spacing: -0.2px;
    line-height: 26px;
  }
  .has-error .ant-form-explain,
  .has-error .ant-form-split,
  .ant-form-explain.ant-form-explain--error {
    font-size: 12px;
    letter-spacing: -0.2px;
    line-height: 23px;
    color: $color-incorrect !important;
  }
  // Input overriding
  // .ant-input {
  //   padding-left: 10px !important;
  //   padding-right: 10px !important;
  //   font-size: 16px;
  //   letter-spacing: -0.2px;
  //   line-height: 26px;
  //   color: #454854 !important;
  //   border-color: #c4c5c9 !important;
  //   height: 52px;
  // }

  // .ant-input:not(textarea):not(.ant-cascader-input) {
  //   height: 44px !important;
  // }
  // .ant-input.ant-input-disabled {
  //   background-color: #f8fafb !important;
  //   border-color: transparent !important;
  //   color: #b1b2b7 !important;
  // }
  // .ant-input-prefix + .ant-input {
  //   padding-left: 30px !important;
  // }
  // .ant-input:focus {
  //   box-shadow: none !important;
  //   outline: none !important;
  //   background-color: white !important;
  //   border-radius: 12px !important;
  //   border: 1px solid #3563ff !important;
  // }
  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-size: 16px;
    letter-spacing: -0.2px;
    line-height: 26px;
    font-weight: 300 !important;
    color: #9b9da0 !important;
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    font-size: 14px;
    letter-spacing: -0.2px;
    line-height: 26px;
    font-weight: 300 !important;
    color: #b1b2b7 !important;
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    font-size: 14px;
    letter-spacing: -0.2px;
    line-height: 26px;
    font-weight: 300 !important;
    color: #b1b2b7 !important;
  }
  :-moz-placeholder {
    /* Firefox 18- */
    font-size: 14px;
    letter-spacing: -0.2px;
    line-height: 26px;
    font-weight: 300 !important;
    color: #b1b2b7 !important;
  }
  // Checkbox overriding
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: $color-primary-1 !important;
  }
  .ant-checkbox-checked:after {
    border-color: $color-primary-1 !important;
  }
  .ant-checkbox-checked .ant-checkbox-inner,
  .ant-checkbox-indeterminate .ant-checkbox-inner {
    background-color: $color-primary-1 !important;
    border-color: $color-primary-1 !important;
  }
  .ant-checkbox {
    vertical-align: sub !important;
  }
  // Select overriding
  .ant-select-selection {
    height: 40px;
    border-color: $gray-5 !important;

    .ant-select-selection__rendered {
      line-height: 40px !important;
      /* 졸업여부: */
      font-size: 14px;
      color: #62656f;
      letter-spacing: -0.2px;
      line-height: 18px;
    }
  }
  .ant-select-focused .ant-select-selection,
  .ant-select-selection:focus,
  .ant-select-selection:active {
    box-shadow: 0 0 0 2px $color-primary-1 !important;
  }
  // Input overriding
  // .ant-input {
  //   padding-left: 20px !important;
  //   padding-right: 10px !important;
  //   font-size: 16px;
  //   letter-spacing: -0.2px;
  //   line-height: 26px;
  //   color: #454854 !important;
  //   border-color: #dadcdf !important;
  // }
  // .ant-input:not(textarea):not(.ant-cascader-input) {
  //   height: 44px !important;
  //   outline: none;
  //   text-decoration: none;
  // }
  // // Select - Cascader
  // .ant-cascader-input.ant-input {
  //   height: 28px;
  //   line-height: 28px !important;
  //   padding-left: 7px !important;
  //   padding-right: 7px !important;
  //   font-size: 12px !important;

  //   &::-webkit-input-placeholder {
  //     /* Chrome/Opera/Safari */
  //     font-size: 12px !important;
  //     font-weight: 400 !important;
  //   }
  //   &::-moz-placeholder {
  //     /* Firefox 19+ */
  //     font-size: 12px !important;
  //     font-weight: 400 !important;
  //   }
  //   &:-ms-input-placeholder {
  //     /* IE 10+ */
  //     font-size: 12px !important;
  //     font-weight: 400 !important;
  //   }
  //   &:-moz-placeholder {
  //     /* Firefox 18- */
  //     font-size: 12px !important;
  //     font-weight: 400 !important;
  //   }
  // }

  // 윗부분
  .top-header {
    width: 400px;
    padding-bottom: 8px;
    border-bottom: 2px solid $color-primary-1;

    .top-header__title {
      /* 회원가입: */
      font-size: 30px;
      font-weight: bold;
      color: #3b3e4b;
      letter-spacing: -0.21px;
      line-height: 45px;
    }
    .top-header__description {
      /* 반갑습니다. iGoGo에 오신것을 환: */
      font-size: 12px;
      color: #9b9b9b;
      letter-spacing: -0.2px;
      line-height: 23px;
    }
  }
  .inEdufessional {
    display: none;
  }
}
.container {
  margin: 0 auto;
  width: 1188px;
  min-width: 1188px;
  // padding-left: 100px;
}
.header-1 {
  font-size: $header-1;
}
.mobile-block {
  display: none;
}
.kakaoPlusFriendsFixed {
  img {
    position: fixed;
    bottom: 21px;
    right: 24px;
    transition: transform 1s;
  }
  img:hover {
    transform: rotate(90deg);
  }
}
@media only screen and (max-device-width: $mobile-max-width) {
  html,
  body {
    min-width: $container-width-mobile !important;
  }
  body {
    width: 100%;
  }
  .desktop-block {
    display: none;
  }
  .mobile-block {
    display: block;
  }
  .container {
    width: $container-width-mobile !important;
    min-width: $container-width-mobile !important;
    margin: 0 auto;
    padding-left: 0px;
  }

  .App.ant-layout {
    // padding-top: 60px;
    // border:3px solid springgreen;
  }
  .top-header {
    width: 100% !important;
  }
  .appBannerBottom {
    z-index: 99;
    position: fixed;
    background-color: #e0f0ff;
    // border:2px solid red;
    // font-size: 20px;
    // font-weight: bold;
    padding-right: 16px;
    padding-left: 14px;

    display: flex;
    justify-content: space-between;
    // justify-content: center;
    align-items: center;
    // color: #ffffff;
    cursor: pointer;
    bottom: 0px;
    right: 0px;
    width: 100%;
    height: 100px;
    .title {
      font-size: 21px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #242424;
      margin-bottom: 2px;
    }
    .subTitle {
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #66686b;
      // margin-right: 43px;
    }
    .button {
      background-color: #ff3d8f;
      width: 96px;
      height: 44px;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
    }
    .logoBackground {
      background-color: white;
      width: 60px;
      height: 60px;
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 12px;
    }
  }
  .appBannerBottomClassPage {
    z-index: 99;
    position: fixed;
    padding-right: 16px;
    padding-left: 16px;

    cursor: pointer;
    bottom: 16px;
    right: 0px;
    width: 100%;

    .button {
      background-color: #ff3d8f;
      width: 100%;
      height: 56px;
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 12px;
      box-shadow: 0 30px 29px -16px rgba(0, 0, 0, 0.2);
    }
  }
  .kakaoPlusFriendsFixed {
    img {
      position: fixed;
      bottom: 81px;
      right: 24px;
      width: 60px;
      height: 60px;
      transition: transform 1s;
    }
    img:hover {
      transform: rotate(0deg);
    }
  }

  .app-modal {
    // 레이아웃 큰틀
    &.ant-layout {
      min-height: 100%;
      background: #fff;
    }

    // 레이아웃 헤더
    & > .ant-layout-header {
      height: 75px !important;
      padding: 0;
    }
  }
}

@media only screen and (max-device-width: $mobile-max-width) {
  .ant-select-selection__rendered {
    font-size: 13px !important;
    line-height: 34px !important;
  }
}

.product-detail-title {
  color: var(--Neutral-100, #242424);
  font-family: 'Pretendard';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  margin-bottom: 12px;
}

.product-detail-subtitle {
  color: var(--Neutral-90, #424242);
  font-family: 'Pretendard';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  margin-top: 20px;
  margin-bottom: 8px;
}
